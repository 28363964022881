import { gql } from '@apollo/client';

export const resetPasswordCode = gql`
  mutation ResetPasswordCode($email: String, $phone: String) {
    resetPasswordCode(email: $email, phone: $phone) {
      success
      message
      error {
        message
        stack
      }
    }
  }
`;

export const completeResetPassword = gql`
  mutation CompleteResetPassword(
    $code: String!
    $password: String!
    $email: String
    $phone: String
  ) {
    completeResetPassword(code: $code, password: $password, email: $email, phone: $phone) {
      success
      message
      error {
        message
        stack
      }
    }
  }
`;
