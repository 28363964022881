import { useScopedI18n } from '@/shared/locales/client';
import { Button, TextInput } from '@/shared/ui';
import classNames from 'classnames';
import { ChangeEvent, FC, useState } from 'react';
import { useResend } from '@/features/validation/lib/use-resend';
import { Password, PasswordField } from '@/features/registration/ui/password';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { completeResetPassword, resetPasswordCode } from '@/features/password-recovery/lib/api';
import { RecoverySteps } from '@/features/password-recovery/lib/use-recovery';
import { RoutesConfig } from '@/shared/routes/config';
import {
  sendRestorePasswordErrorEvent,
  sendRestorePasswordNewPassEvent,
  sendRestorePasswordNewPassRepeatEvent,
  sendRestorePasswordNewPassSubmitEvent,
  sendRestorePasswordResendClickEvent,
  sendRestorePasswordSmsInputEvent,
  sendRestorePasswordSuccessEvent
} from '@/shared/lib/gtm/events/restore-password-events';
import { updateTarget } from '@/shared/lib/helpers/parse-password';
import { useRouter } from 'next/navigation';

type Props = {
  phone: string | null;
  email: string | null;
  setRecoveryStep: (step: string) => void;
  handleClose?: () => void;
};

const defaultValues = {
  code: '',
  [PasswordField.Password]: '',
  password2: ''
};

export type ResetPasswordFields = {
  code?: string;
  [PasswordField.Password]?: string;
  password2?: string;
};

export const CodeConfirmation: FC<Props> = ({ phone, email, setRecoveryStep, handleClose }) => {
  const [codeValue, setCodeValue] = useState('');
  const [lastCode, setLastCode] = useState<null | string>(null);
  const [validationError, setValidationError] = useState(false);
  const [touched, setTouched] = useState(false);
  const t = useScopedI18n('recovery');
  const regT = useScopedI18n('register');
  const { formattedTime, sendCodeReady, resetTimer } = useResend();
  const router = useRouter();
  const [resetPassword, { data }] = useMutation(completeResetPassword);
  const [sendCode] = useMutation(resetPasswordCode);
  const { control, handleSubmit, formState, getValues, setError, clearErrors } =
    useForm<ResetPasswordFields>({
      mode: 'onTouched',
      defaultValues
    });
  const { isValid, errors } = formState;

  const handleResend = async () => {
    sendRestorePasswordResendClickEvent();
    if (!sendCodeReady) return;
    try {
      await sendCode({ variables: { email, phone } });
      resetTimer();
    } catch (e) {
      console.log(e);
    }
  };

  const getError = () => {
    if (touched && !codeValue) {
      return t('errors.required');
    }
    if (validationError) {
      return t('errors.code');
    }
    return undefined;
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValidationError(false);
    if (e.target.value?.length < 5 && /^[0-9]*$/.test(e.target.value)) {
      setCodeValue(e.target.value);
    }
  };

  const onSubmit = async (e: ResetPasswordFields) => {
    try {
      const resp = await resetPassword({
        variables: { phone, email, password: e.password, code: codeValue }
      });

      const errorMessage = resp?.data?.completeResetPassword?.error?.message;

      if (errorMessage) {
        sendRestorePasswordErrorEvent(errorMessage);
        throw new Error(errorMessage);
      }

      sendRestorePasswordSuccessEvent();
      setRecoveryStep(RecoverySteps.Success);
    } catch (e: any) {
      sendRestorePasswordErrorEvent(e.message);
      setValidationError(true);
    } finally {
      setLastCode(codeValue);
    }
  };

  const handlePrevStep = () => {
    const recoveryType = email ? t('email') : t('phone');
    setRecoveryStep(recoveryType);
  };

  const resendClassNames = classNames(
    'mt-2 block cursor-pointer text-base text-primary underline decoration-primary decoration-dotted md:mt-2t md:w-full md:text-center md:text-xl'
  );

  const handlePasswordChange = () => {
    const { password, password2 } = getValues();

    if (password2 === password && errors.password2?.message === t('errors.passwordMatch')) {
      clearErrors('password2');
    } else if (password2 !== password && (password2 || '').length > 0) {
      setError('password2', { type: 'validate', message: t('errors.passwordMatch') });
    }
  };

  return (
    <div className="w-full rounded-md md:w-58t">
      <h1 className="text-gray-900 mb-2t text-lg font-medium tracking-tight text-primary md:text-3xl">
        {t('title')}
      </h1>
      <div>
        <span className="text-base text-white md:text-xl">
          {t('enterCode')} <br /> <b>{email || `+${phone}`}</b>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            type="text"
            value={codeValue}
            onChange={handleCodeChange}
            name="code"
            placeholder={t('enterCode')}
            className="mt-1 md:mt-1t"
            errorMessage={getError()}
            onBlur={() => setTouched(true)}
            inputMode="numeric"
            autoComplete="one-time-code"
            id="code"
            onInputStart={() => sendRestorePasswordSmsInputEvent()}
          />
          <span onClick={handleResend} className={resendClassNames}>
            {sendCodeReady ? t('resend') : t('resendWithSeconds', { seconds: formattedTime })}
          </span>

          <Password
            control={control}
            t={regT}
            onChange={handlePasswordChange}
            autoComplete="new-password"
            id="new-password"
            onInputStart={() => sendRestorePasswordNewPassEvent()}
          />
          <Controller
            name="password2"
            control={control}
            rules={{
              required: t('errors.required'),
              validate: (value) => {
                const { password } = getValues();
                return (password !== value && t('errors.passwordMatch')) || undefined;
              }
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <TextInput
                    label={t('fields.passwordRepeat')}
                    name={field.name}
                    type={'password'}
                    onChange={(e) => {
                      const updatedTarget = updateTarget(e);
                      field.onChange({ ...e, target: updatedTarget });
                    }}
                    value={field.value}
                    onInputStart={() => sendRestorePasswordNewPassRepeatEvent()}
                    errorMessage={error?.message}
                    onBlur={field.onBlur}
                    autoComplete="new-password"
                    id="new-password"
                  />
                </>
              );
            }}
          />

          <Button
            type="submit"
            disabled={codeValue?.length < 4 || lastCode === codeValue || !isValid}
            className="mt-4 w-full md:mt-4t"
            onClick={() => sendRestorePasswordNewPassSubmitEvent()}
          >
            {t('fields.submit')}
          </Button>
          <Button
            onClick={() => {
              router.push('?login=true');
            }}
            variant="border"
            className="mt-2 w-full md:mt-2t"
          >
            {t('cancel')}
          </Button>
        </form>
        <button
          type="button"
          onClick={handlePrevStep}
          className="mt-2 block cursor-pointer text-base text-primary underline decoration-primary md:mt-2t md:text-xl"
        >
          {t('toPrevStep')}
        </button>
      </div>
    </div>
  );
};
