import { FORBIDDEN_SYMBOLS } from '@/shared/const/forbidden-password-symbols';
import { ChangeEvent } from 'react';

export const updateTarget = (e: ChangeEvent<HTMLInputElement>) => {
  const filteredValue = e.target.value
    .split('')
    .filter((symb) => !FORBIDDEN_SYMBOLS.includes(symb))
    .join('');

  const { target } = e;
  return { ...target, value: filteredValue };
};
