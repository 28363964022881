'use client';
import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import classNames from 'classnames';

import Tabs from './Tabs';
import Tab, { Props as TabProps } from './Tab';
import { ArrowIcon, ArrowLeftIcon, Button } from '@/shared/ui';

type Props = {
  title: string;
  wide?: boolean;
  sideContent?: React.ReactNode;
  disablePadding?: boolean;
  children?: React.ReactNode | undefined;
  initialTab?: string;
  onTabChange?: (tab: string) => void;
  isMobileModalHeader?: boolean;
  onMobileArrowClick?: () => void;
  withoutPadding?: boolean;
  hideIfSingleTab?: boolean;
};

const FormWrapper: React.FC<PropsWithChildren<Props>> & { Tab: React.FC<TabProps> } = ({
  title,
  wide,
  children,
  sideContent,
  disablePadding,
  initialTab,
  onTabChange,
  isMobileModalHeader,
  onMobileArrowClick,
  withoutPadding,
  hideIfSingleTab
}) => {
  const [selected, setSelected] = React.useState(initialTab || '');

  const onSelect = (tab: string) => {
    setSelected(tab);
    if (onTabChange) onTabChange(tab);
  };

  const childrenArray = React.Children.toArray(children);
  const tabLabels = React.Children.map(
    childrenArray,
    (child) => (child as React.ReactElement<TabProps>).props.label
  );

  const content = React.Children.map(childrenArray, (child, i) => {
    const typedChild = child as ReactElement<TabProps>;
    if (!selected && i === 0) {
      onSelect(typedChild.props.label);
      return child;
    }
    if (selected && typedChild.props.label === selected) {
      return child;
    }
  });

  const minTabsLength = hideIfSingleTab ? 2 : 1;
  const showTabs = tabLabels && tabLabels.length >= minTabsLength;

  useEffect(() => {
    if (initialTab) onSelect(initialTab);
  }, [initialTab]);

  return (
    <div
      className={classNames(
        'max-w-[756px] break-words rounded-xm font-roboto shadow md:break-normal',
        wide ? 'w-full' : 'w-full md:w-[490px]'
      )}
    >
      <div
        className={classNames({
          ['relative flex flex-col items-center md:items-start']: isMobileModalHeader
        })}
      >
        <Button
          onClick={onMobileArrowClick}
          className={
            'absolute left-0 top-[1px] !m-0 !h-[12px] !w-[12px] !min-w-[12px] !p-0 md:hidden'
          }
          variant="empty"
        >
          <ArrowLeftIcon className={'text-text-brand '} />
        </Button>
        {title && (
          <h1
            className={classNames(
              'text-gray-900 mb-[22px] text-18 font-medium tracking-tight text-primary md:text-24',
              { ['block text-18 font-bold leading-[20px] text-text-primary']: isMobileModalHeader },
              showTabs ? 'md:mb-2t' : 'md:mb-2'
            )}
          >
            {title}
          </h1>
        )}
      </div>
      {showTabs && (
        <Tabs
          withoutPadding={withoutPadding}
          onChange={onSelect}
          tabs={tabLabels}
          wide={wide}
          sideContent={sideContent}
          selected={selected}
        />
      )}
      <div
        className={classNames('rounded-b-md px-2 py-4 md:rounded-tr-md md:p-5', {
          '!p-0': disablePadding
        })}
      >
        {content}
      </div>
    </div>
  );
};

FormWrapper.Tab = Tab;

export default FormWrapper;
