import { FC, useState } from 'react';
import { useScopedI18n } from '@/shared/locales/client';
import { Button } from '@/shared/ui';
import { Controller, useForm } from 'react-hook-form';
import AgreeSection, { AgreeSectionFieldNames } from '@/features/registration/ui/agree-section';
import { PromoField } from '@/features/registration/ui/registration-promo';
import { Password, PasswordField } from '@/features/registration/ui/password';
import { CustomPhoneInput } from '@/shared/ui/new-phone-input';
import Link from 'next/link';
import {
  checkCountryInBlacklist,
  countries,
  detectCountryByNumber,
  excludeCountries
} from '@/shared/ui/new-phone-input/countries';
import { RegistrationType } from '@/features/registration/lib/use-registration';
import {
  sendRegistrationFormButtonSubmitEvent,
  sendRegistrationFormInputEvent,
  sendRegistrationPasswordInputEvent
} from '@/shared/lib/gtm/events/registration-events';
import {
  ALREADY_IN_USE_ERROR,
  EMAIL_BELONGS_TO_OTHER_USER_ERROR,
  PHONE_BELONGS_TO_OTHER_USER_ERROR,
} from '@/features/registration/lib/const';
import { useBlacklist } from '@/entities/blacklist';
import { Block } from '@/shared/ui/block';

export enum PhoneRegFormFieldNames {
  Phone = 'phone'
}

export type PhoneRegFormValues = {
  [AgreeSectionFieldNames.RuleAgreement]?: boolean;
  [AgreeSectionFieldNames.PersonalData]?: boolean;
  [PromoField.Promo]?: string;
  [PhoneRegFormFieldNames.Phone]?: string;
  [PasswordField.Password]?: string;
};

type Props = {
  promo?: string;
  handleSubmitForm: (data: PhoneRegFormValues) => Promise<{ success: boolean; message?: string }>;
  registrationData: PhoneRegFormValues;
  countryCode: string;
  setCountryCode: (arg: string) => void;
  isUnconfirmedAllowed: boolean;
  handleRegUnconfirmed: (
    method: RegistrationType,
    formData: PhoneRegFormValues
  ) => Promise<{ success: boolean; message?: string }>;
  setRegistrationInProgress?: (status: boolean) => void;
};

const defaultValues = {
  [AgreeSectionFieldNames.RuleAgreement]: true,
  [AgreeSectionFieldNames.PersonalData]: true,
  [PromoField.Promo]: '',
  [PhoneRegFormFieldNames.Phone]: '',
  [PasswordField.Password]: ''
};

export const PhoneRegistrationForm: FC<Props> = ({
  promo,
  handleSubmitForm,
  registrationData,
  countryCode,
  setCountryCode,
  isUnconfirmedAllowed,
  handleRegUnconfirmed,
  setRegistrationInProgress
}) => {
  const t = useScopedI18n('register');

  const { phone, password, RuleAgreement } = registrationData;

  const [error, setError] = useState<string | null>(null);
  const [lastUsedSource, setLastUsedSource] = useState<string | null>(null);

  const { countries: blacklistCountries, loading } = useBlacklist();

  const handleSubmitWithError = async (data: PhoneRegFormValues) => {
    /* setRegistrationInProgress(true);*/
    if (!isUnconfirmedAllowed) {
      const res = await handleSubmitForm(data);
      if (!res.success && res.message) {
        setError(res.message);
      }
    }
    if (isUnconfirmedAllowed) {
      const res = await handleRegUnconfirmed('phone', data);
      if (!res.success && res.message) {
        setError(res.message);
        if (res.message === ALREADY_IN_USE_ERROR && data) {
          setLastUsedSource(data?.[PhoneRegFormFieldNames.Phone] as string);
        }
      }
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch
  } = useForm<PhoneRegFormValues>({
    defaultValues: {
      ...defaultValues,
      [PromoField.Promo]: promo,
      phone,
      password,
      RuleAgreement
    },
    mode: 'onTouched'
  });

  const { phone: phoneValue } = watch();

  const onSubmit = (data: PhoneRegFormValues) => {
    void handleSubmitWithError({
      ...data,
      [PhoneRegFormFieldNames.Phone]: data[PhoneRegFormFieldNames.Phone]?.replace(/\D/g, '')
    });
  };

  if (loading) {
    return null;
  }

  return (
    <Block className="md:px-0 md:pb-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={PhoneRegFormFieldNames.Phone}
          control={control}
          rules={{
            required: t('errors.required'),
            pattern: { value: /^[^_]+$/, message: t('errors.phone') },
            validate: (value) => {
              const countryIso = detectCountryByNumber(value).iso;

              if (checkCountryInBlacklist({ countryIso, blacklist: blacklistCountries })) {
                return t('errors.phoneCode');
              }

              if (countryIso === countries[0].iso) {
                return t('errors.phone');
              }

              return true;
            }
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <CustomPhoneInput
                countries={excludeCountries({ countries, excludeCountries: blacklistCountries })}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                value={field.value}
                onInputStart={() => sendRegistrationFormInputEvent('phone')}
                onChange={(e) => {
                  setError(null);
                  field.onChange(e);
                }}
                name={field.name}
                errorMessage={error?.message}
                onBlur={field.onBlur}
              />
            );
          }}
        />
        <Password
          onChange={() => setError(null)}
          control={control}
          placeholder={t('fields.password')}
          t={t}
          onInputStart={() => sendRegistrationPasswordInputEvent('phone')}
        />
        <AgreeSection control={control} />
        <div className="mt-2 w-full md:mt-4">
          {error &&
            error === ALREADY_IN_USE_ERROR &&
            phoneValue?.replace(/\D/g, '') === lastUsedSource && (
              <div className="mb-1 w-full rounded-1 bg-yellow-attention px-2 py-2 text-12 leading-16 text-text-warning md:mb-2 md:rounded-2 md:px-4 md:py-2 md:text-lg md:font-medium md:leading-[20px]">
                {t('errors.alreadyInUse')}
              </div>
            )}
          {error && error !== ALREADY_IN_USE_ERROR && (
            <div className="mb-1 w-full rounded-1 bg-yellow-attention px-2 py-2 text-12 leading-16 text-text-warning md:mb-2 md:rounded-2 md:px-4 md:py-2 md:text-lg md:font-medium md:leading-[20px]">
              {error === PHONE_BELONGS_TO_OTHER_USER_ERROR ? t('errors.unsuccessfulRegistration') : error}
            </div>
          )}
          <Button
            name="submit"
            type="submit"
            size="lg"
            className="h-6 w-full text-12 md:text-18"
            onClick={() => {
              sendRegistrationFormButtonSubmitEvent('phone');
              handleSubmit(onSubmit);
            }}
            disabled={!isValid || !!error}
          >
            {t('fields.submit')}
          </Button>
        </div>
      </form>
      <div className={'leading-20 mt-2 text-14 md:mt-1'}>
        <span className={'text-text-primary'}>{t('alreadyRegistered')}</span>{' '}
        <Link className={'text-primary underline'} href={`?login=true&type=email`}>
          {t('loginHere')}
        </Link>
      </div>
    </Block>
  );
};
