import { useState } from 'react';

type T = (str: string, ...args: any) => string;

type UseRecovery = (T: T) => {
  recoveryStep: string;
  setRecoveryStep: (step: string) => void;
  phone: string | null;
  setPhone: (phone: string) => void;
  email: string | null;
  setEmail: (email: string) => void;
};

export enum RecoverySteps {
  CodeConfirmation = 'CodeConfirmation',
  Success = 'Success'
}

export const useRecovery: UseRecovery = (t) => {
  const [recoveryStep, setRecoveryStep] = useState<string>(t('email'));
  const [phone, setPhone] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  return {
    recoveryStep,
    setRecoveryStep,
    email,
    setEmail,
    phone,
    setPhone
  };
};
