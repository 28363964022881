'use client';

import { useScopedI18n } from '@/shared/locales/client';
import { Control, Controller } from 'react-hook-form';
import React, { FC } from 'react';
import { EmailRegFormValues } from '@/features/registration/ui/email-registration-form';
import { PhoneRegFormValues } from '@/features/registration/ui/phone-registration-form';
import { ExpandableInput, TextInput } from '@/shared/ui';

type Props = {
  control: Control<EmailRegFormValues | PhoneRegFormValues>;
  promo?: string;
};

export enum PromoField {
  Promo = 'Promo'
}

export const RegistrationPromo: FC<Props> = ({ control, promo }) => {
  const t = useScopedI18n('register');

  return (
    <Controller
      name={PromoField.Promo}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <ExpandableInput className={'mt-1'} key={'1'} text={t('promoButton')} open={!!promo}>
          <TextInput
            label={t('fields.traits.promo')}
            name={field.name}
            onChange={field.onChange}
            value={field.value}
            errorMessage={error?.message}
            onBlur={field.onBlur}
          />
        </ExpandableInput>
      )}
    />
  );
};
