'use client';

import classNames from 'classnames';

type Props = {
  onChange: (value: string) => void;
  tabs: string[];
  wide?: boolean;
  sideContent?: React.ReactNode;
  selected: string;
  withoutPadding?: boolean;
};

const Tabs: React.FC<Props> = ({ onChange, wide, tabs, sideContent, selected, withoutPadding }) => {
  const itemClassName = classNames(
    'typography-button-medium h-[34px] flex-1 md:h-[52px] py-[16px] text-text-primary flex justify-center items-center md:text-16 cursor-pointer rounded-m whitespace-nowrap',
    wide ? 'w-full px-2 md:px-2t md:w-max' : 'w-9t md:w-14t'
  );
  const selectedClassNames = 'bg-background-tag md:bg-main-light text-text-primary';

  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div
      className={classNames(
        'mb-[8px] flex justify-between rounded-t-xm pb-0 md:mb-0 md:bg-background-card md:p-[16px]',
        { ['md:!px-0']: withoutPadding }
      )}
    >
      <ul className="flex w-full rounded-m bg-background-card p-[4px] md:bg-body">
        {tabs.map((item) => (
          <li
            key={item}
            className={classNames(itemClassName, {
              [selectedClassNames]: selected === item
            })}
            onClick={() => handleChange(item)}
          >
            {item}
          </li>
        ))}
      </ul>
      {sideContent && <>{sideContent}</>}
    </div>
  );
};

export default Tabs;
