'use client';

import React, { useState } from 'react';

import { useScopedI18n } from '@/shared/locales/client';
import { Controller, useForm } from 'react-hook-form';
import AgreeSection, { AgreeSectionFieldNames } from '@/features/registration/ui/agree-section';
import { PromoField } from '@/features/registration/ui/registration-promo';
import { Button, TextInput } from '@/shared/ui';
import { Password, PasswordField } from '@/features/registration/ui/password';
import { validateEmail } from '@/shared/lib/text-vallidators';
import { RegistrationType } from '@/features/registration/lib/use-registration';
import {
  sendRegistrationFormButtonSubmitEvent,
  sendRegistrationFormInputEvent,
  sendRegistrationPasswordInputEvent
} from '@/shared/lib/gtm/events/registration-events';
import { ALREADY_IN_USE_ERROR, EMAIL_BELONGS_TO_OTHER_USER_ERROR } from '@/features/registration/lib/const';
import { Block } from '@/shared/ui/block';
import Link from 'next/link';
import classNames from 'classnames';

export enum EmailRegFormFieldNames {
  Email = 'email',
  EmailRegFormFieldNames = 'EmailRegFormFieldNames'
}

export type EmailRegFormValues = {
  [AgreeSectionFieldNames.RuleAgreement]?: boolean;
  [AgreeSectionFieldNames.PersonalData]?: boolean;
  [PromoField.Promo]?: string;
  [EmailRegFormFieldNames.Email]?: string;
  [PasswordField.Password]?: string;
};

type Props = {
  promo?: string;
  handleSubmitForm: (data: EmailRegFormValues) => Promise<{ success: boolean; message?: string }>;
  registrationData: EmailRegFormValues;
  isUnconfirmedAllowed: boolean;
  handleRegUnconfirmed: (
    method: RegistrationType,
    formData: EmailRegFormValues
  ) => Promise<{ success: boolean; message?: string }>;
  setRegistrationInProgress?: (status: boolean) => void;
};

const defaultValues = {
  [AgreeSectionFieldNames.RuleAgreement]: true,
  [AgreeSectionFieldNames.PersonalData]: true,
  [EmailRegFormFieldNames.Email]: '',
  [PasswordField.Password]: ''
};

export const EmailRegistrationForm = ({
  promo,
  handleSubmitForm,
  registrationData,
  isUnconfirmedAllowed,
  handleRegUnconfirmed
}: Props) => {
  const t = useScopedI18n('register');
  const { email, password, RuleAgreement } = registrationData;

  const [error, setError] = useState<string | null>(null);
  const [lastUsedSource, setLastUsedSource] = useState<string | null>(null);

  const handleSubmitWithError = async (data: EmailRegFormValues) => {
    if (!isUnconfirmedAllowed) {
      const res = await handleSubmitForm(data);
      if (!res.success && res.message) {
        setError(res.message);
      }
    }
    if (isUnconfirmedAllowed) {
      const res = await handleRegUnconfirmed('email', data);

      if (!res.success && res.message) {
        setError(res.message);
        if (res.message === ALREADY_IN_USE_ERROR && data) {
          setLastUsedSource(data?.[EmailRegFormFieldNames.Email] as string);
        }
      }
    }
  };

  const onSubmit = (data: EmailRegFormValues) => {
    sendRegistrationFormButtonSubmitEvent('email');
    void handleSubmitWithError(data);
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch
  } = useForm<EmailRegFormValues>({
    defaultValues: {
      ...defaultValues,
      [PromoField.Promo]: promo,
      email,
      password,
      RuleAgreement
    },
    mode: 'onTouched'
  });

  const { email: emailValue } = watch();
  console.log(error)
  return (
    <Block className={'md:px-0 md:pb-0'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={EmailRegFormFieldNames.Email}
          rules={{
            required: t('errors.required'),
            validate: (value) => validateEmail(value, t('errors.email'))
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              placeholder={t('fields.traits.email')}
              name={field.name}
              onChange={(e) => {
                setError(null);
                field.onChange(e);
              }}
              onInputStart={() => sendRegistrationFormInputEvent('email')}
              value={field.value}
              errorMessage={error?.message}
              onBlur={field.onBlur}
              className={classNames('md:min-h-[32px]')}
            />
          )}
        />
        <Password
          control={control}
          placeholder={t('fields.password')}
          t={t}
          onInputStart={() => sendRegistrationPasswordInputEvent('email')}
          onChange={() => setError(null)}
        />
        <AgreeSection control={control} />
        <div className="mt-2 w-full md:mt-4">
          {error && error === ALREADY_IN_USE_ERROR && emailValue === lastUsedSource && (
            <div className="mb-1 w-full rounded-1 bg-yellow-attention px-2 py-2 text-12 leading-16 text-text-warning md:mb-2t md:rounded-2 md:px-4 md:py-2 md:text-lg md:font-medium md:leading-[20px]">
              {t('errors.alreadyInUse')}
            </div>
          )}
          {error && error !== ALREADY_IN_USE_ERROR && (
            <div className="mb-1 w-full rounded-1 bg-yellow-attention px-2 py-2 text-12 leading-16 text-text-warning md:mb-2t md:rounded-2 md:px-4 md:py-2 md:text-lg md:font-medium md:leading-[20px]">
              {error === EMAIL_BELONGS_TO_OTHER_USER_ERROR ? t('errors.unsuccessfulRegistration') : error}
            </div>
          )}
          <Button
            name="submit"
            type="submit"
            size="lg"
            className="h-6 w-full text-12 md:text-18"
            onClick={() => {
              sendRegistrationFormButtonSubmitEvent('email');
              handleSubmit(onSubmit);
            }}
            disabled={!isValid || !!error}
          >
            {t('fields.submit')}
          </Button>
        </div>
      </form>
      <div className={'leading-20 mt-2 text-14 md:mt-1'}>
        <span className={'text-text-primary'}>{t('alreadyRegistered')}</span>{' '}
        <Link className={'text-primary underline'} href={`?login=true&type=email`}>
          {t('loginHere')}
        </Link>
      </div>
    </Block>
  );
};
