'use client';

import React from 'react';

import { useScopedI18n } from '@/shared/locales/client';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextInput } from '@/shared/ui';
import Link from 'next/link';
import { RoutesConfig } from '@/shared/routes/config';
import { EmailRegFormFieldNames } from '@/features/registration';
import { validateEmail } from '@/shared/lib/text-vallidators';
import { RecoverySteps } from '@/features/password-recovery/lib/use-recovery';
import { resetPasswordCode } from '@/features/password-recovery/lib/api';
import { useMutation } from '@apollo/client';
import {
  sendRestorePasswordBackClickEvent,
  sendRestorePasswordButtonSubmitEvent,
  sendRestorePasswordEmailFieldEvent
} from '@/shared/lib/gtm/events/restore-password-events';

type Props = {
  setEmail: (email: string) => void;
  setRecoveryStep: (step: string) => void;
  email?: string | null;
};

const defaultValues = {
  email: ''
};

export const EmailRecoveryForm: React.FC<Props> = ({ setEmail, setRecoveryStep, email }) => {
  const t = useScopedI18n('recovery');
  const [sendCode, { data }] = useMutation(resetPasswordCode);

  const { control, handleSubmit, formState, setError } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: email || ''
    }
  });
  const { isValid } = formState;

  const onSubmit = async (e: typeof defaultValues) => {
    try {
      await sendCode({ variables: { email: e.email } });
      setEmail(e.email);
      sendRestorePasswordButtonSubmitEvent();
      setRecoveryStep(RecoverySteps.CodeConfirmation);
    } catch (e: any) {
      setError(EmailRegFormFieldNames.Email, { type: 'validate', message: e?.message });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={EmailRegFormFieldNames.Email}
          rules={{
            required: t('errors.required'),
            validate: (value) => validateEmail(value, t('errors.email'))
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              label={t('fields.email')}
              name={field.name}
              onChange={field.onChange}
              value={field.value}
              errorMessage={error?.message}
              onBlur={field.onBlur}
              onInputStart={() => sendRestorePasswordEmailFieldEvent()}
            />
          )}
        />
        <Button
          name="submit"
          type="submit"
          size="lg"
          className="mt-4 h-6 w-full md:mt-5"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          {t('fields.submit')}
        </Button>
      </form>
      <div className="mt-4">
        <Link
          className="text-primary underline"
          onClick={() => sendRestorePasswordBackClickEvent()}
          href={`${RoutesConfig.login}?type=phone`}
        >
          {t('backToAuth')}
        </Link>
      </div>
    </div>
  );
};
