import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const RecoverySucceedIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={148} viewBox="0 0 148 100" fill="none" className={className}>
    <rect width="100" height="100" rx="4" fill="#F4F4F4" />
    <g opacity="0.2">
      <path
        d="M77.0288 60L77.5505 53.5764L72.0097 57.2396L69.3652 52.7604L75.3738 50L69.3652 47.2396L72.0097 42.7604L77.5505 46.4236L77.0288 40H82.3358L81.8141 46.4236L87.3549 42.7604L89.9994 47.2396L83.9908 50L89.9994 52.7604L87.3549 57.2396L81.8141 53.5764L82.3358 60H77.0288Z"
        fill="black"
      />
      <path
        d="M47.3472 60L47.8689 53.5764L42.3281 57.2396L39.6836 52.7604L45.6921 50L39.6836 47.2396L42.3281 42.7604L47.8689 46.4236L47.3472 40H52.6541L52.1324 46.4236L57.6733 42.7604L60.3177 47.2396L54.3092 50L60.3177 52.7604L57.6733 57.2396L52.1324 53.5764L52.6541 60H47.3472Z"
        fill="black"
      />
      <path
        d="M17.6636 60L18.1853 53.5764L12.6445 57.2396L10 52.7604L16.0085 50L10 47.2396L12.6445 42.7604L18.1853 46.4236L17.6636 40H22.9705L22.4488 46.4236L27.9897 42.7604L30.6341 47.2396L24.6256 50L30.6341 52.7604L27.9897 57.2396L22.4488 53.5764L22.9705 60H17.6636Z"
        fill="black"
      />
    </g>
    <rect
      x="71"
      y="41.9961"
      width="13.7015"
      height="42.209"
      transform="rotate(-45 71 41.9961)"
      fill="#00C7B1"
    />
    <rect
      x="138.285"
      y="15"
      width="13.7015"
      height="66.6992"
      transform="rotate(45 138.285 15)"
      fill="#00C7B1"
    />
  </SvgIcon>
);
