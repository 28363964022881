'use client';

import FormWrapper from '@/features/iam/ui/FormWrapper';
import { useScopedI18n } from '@/shared/locales/client';
import { PhoneRecoveryForm } from '@/features/password-recovery/ui/phone-recovery-form';
import { EmailRecoveryForm } from '@/features/password-recovery/ui/email-recovery-form';
import { RecoverySteps, useRecovery } from '@/features/password-recovery/lib/use-recovery';
import { CodeConfirmation } from '@/features/password-recovery/ui/code-confirmation';
import RecoverySuccessScreen from '@/features/password-recovery/ui/recovery-success-screen';
import { useUserAccess } from '@/entities/blocked-ip';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { RegistrationType } from '@/features/registration/lib/use-registration';

type Props = {
  registrationTypes?: RegistrationType[];
  handleClose?: () => void;
  authByPhoneRestricted?: boolean | null;
};

export const PasswordRecoveryScreen: React.FC<Props> = ({
  registrationTypes,
  handleClose,
  authByPhoneRestricted
}) => {
  const t = useScopedI18n('recovery');
  const { recoveryStep, setRecoveryStep, setPhone, setEmail, phone, email } = useRecovery(t);
  const { push } = useRouter();
  const setOnlyPhone = (phone: string) => {
    setPhone(phone);
    setEmail('');
  };

  const setOnlyEmail = (email: string) => {
    setEmail(email);
    setPhone('');
  };

  const initialRegistrationType = registrationTypes?.[0] ?? 'email';
  const initialTab = initialRegistrationType === 'phone' ? t('phone') : t('email');

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  const onTabChange = (tab: string) => {
    setSelectedTab(tab);
    const recoveryType = tab === t('email') ? 'email' : 'phone';
    push(`?recovery=true&type=${recoveryType}`);
  };

  switch (recoveryStep) {
    case t('phone'):
    case t('email'): {
      return (
        <FormWrapper
          title={t('title')}
          initialTab={authByPhoneRestricted ? t('email') : initialTab}
          onTabChange={onTabChange}
          disablePadding
        >
          {registrationTypes?.includes('phone') &&
            authByPhoneRestricted !== null &&
            !authByPhoneRestricted && (
              <FormWrapper.Tab label={t('phone')}>
                <PhoneRecoveryForm
                  setPhone={setOnlyPhone}
                  setRecoveryStep={setRecoveryStep}
                  phone={phone}
                />
              </FormWrapper.Tab>
            )}
          {registrationTypes?.includes('email') && (
            <FormWrapper.Tab label={t('email')}>
              <EmailRecoveryForm
                setEmail={setOnlyEmail}
                setRecoveryStep={setRecoveryStep}
                email={email}
              />
            </FormWrapper.Tab>
          )}
        </FormWrapper>
      );
    }

    case RecoverySteps.CodeConfirmation: {
      return (
        <CodeConfirmation
          phone={phone}
          email={email}
          setRecoveryStep={setRecoveryStep}
          handleClose={handleClose}
        />
      );
    }

    case RecoverySteps.Success: {
      return <RecoverySuccessScreen />;
    }

    default: {
      return null;
    }
  }
};
