'use client';

import React from 'react';

import { useScopedI18n } from '@/shared/locales/client';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@/shared/ui';
import { CustomPhoneInput } from '@/shared/ui/new-phone-input';
import Link from 'next/link';
import { RoutesConfig } from '@/shared/routes/config';
import { PhoneRegFormFieldNames } from '@/features/registration';
import { RecoverySteps } from '@/features/password-recovery/lib/use-recovery';
import { useMutation } from '@apollo/client';
import { resetPasswordCode } from '@/features/password-recovery/lib/api';
import { countries, detectCountryByNumber } from '@/shared/ui/new-phone-input/countries';
import {
  sendRestorePasswordBackClickEvent,
  sendRestorePasswordPhoneFieldEvent
} from '@/shared/lib/gtm/events/restore-password-events';

type Props = {
  setPhone: (phone: string) => void;
  setRecoveryStep: (step: string) => void;
  phone?: string | null;
};

const defaultValues = {
  phone: ''
};

export const PhoneRecoveryForm: React.FC<Props> = ({ setRecoveryStep, setPhone, phone }) => {
  const t = useScopedI18n('recovery');
  const [sendCode, { data }] = useMutation(resetPasswordCode);

  const { control, handleSubmit, formState, watch } = useForm({
    mode: 'onTouched',
    defaultValues: {
      phone: phone || ''
    }
  });
  const { isValid } = formState;

  const onSubmit = async (e: typeof defaultValues) => {
    const phone = e.phone.replace(/\D/g, '');
    const resp = await sendCode({ variables: { phone: phone } });
    setPhone(phone);
    setRecoveryStep(RecoverySteps.CodeConfirmation);
  };

  return (
    <div className="md:w-58t">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={PhoneRegFormFieldNames.Phone}
          control={control}
          rules={{
            required: t('errors.required'),
            pattern: { value: /^[^_]+$/, message: t('errors.phone') },
            validate: (value) =>
              detectCountryByNumber(value).iso === countries[0].iso ? t('errors.phone') : true
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <CustomPhoneInput
                value={field.value}
                onChange={field.onChange}
                name={field.name}
                onInputStart={() => sendRestorePasswordPhoneFieldEvent()}
                errorMessage={error?.message}
                label={t('fields.phone')}
                onBlur={field.onBlur}
              />
            );
          }}
        />
        <Button
          name="submit"
          type="submit"
          size="lg"
          className="mt-4 h-6 w-full md:mt-5"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          {t('fields.submit')}
        </Button>
      </form>
      <div className="mt-4">
        <Link
          className="text-primary underline"
          onClick={() => sendRestorePasswordBackClickEvent()}
          href={`${RoutesConfig.login}?type=phone`}
        >
          {t('backToAuth')}
        </Link>
      </div>
    </div>
  );
};
