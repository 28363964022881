import { sendGTMEventOnce, sendGTMEvent } from '../events-factory';

export const sendRestorePasswordPhoneFieldEvent = () => {
  sendGTMEventOnce({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_field_phone'
  });
};

export const sendRestorePasswordEmailFieldEvent = () => {
  sendGTMEventOnce({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_field_email'
  });
};

export const sendRestorePasswordSmsInputEvent = () => {
  sendGTMEventOnce({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_field_sms'
  });
};

export const sendRestorePasswordNewPassEvent = () => {
  sendGTMEventOnce({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_new_pass'
  });
};

export const sendRestorePasswordNewPassRepeatEvent = () => {
  sendGTMEventOnce({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_new_pass_repeat'
  });
};

export const sendRestorePasswordButtonSubmitEvent = () => {
  sendGTMEvent({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_button_submit'
  });
};

export const sendRestorePasswordBackClickEvent = () => {
  sendGTMEvent({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_go_back'
  });
};

export const sendRestorePasswordResendClickEvent = () => {
  sendGTMEvent({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_resend_button'
  });
};

export const sendRestorePasswordNewPassSubmitEvent = () => {
  sendGTMEvent({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_new_pass_submit'
  });
};

export const sendRestorePasswordSuccessEvent = () => {
  sendGTMEvent({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_success'
  });
};

export const sendRestorePasswordErrorEvent = (errorText: string) => {
  sendGTMEvent({
    event: 'restore_password',
    eventCategory: 'restore_password',
    eventAction: 'restore_password_error',
    error_text: errorText
  });
};
