'use client';

import React, { useState, FC } from 'react';

import { Control, Controller } from 'react-hook-form';

import { TextInput } from '@/shared/ui';

import {
  validateCharactersInString,
  validateMinLength,
  validateNumbersInString
} from '@/shared/lib/text-vallidators';
import { EmailRegFormValues } from '@/features/registration/ui/email-registration-form';
import { PhoneRegFormValues } from './phone-registration-form';
import classNames from 'classnames';
import { ResetPasswordFields } from '@/features/password-recovery/ui/code-confirmation';
import { FORBIDDEN_SYMBOLS } from '@/shared/const/forbidden-password-symbols';

const MIN_PASSWORD_LENGTH = 8;

type Props = {
  control: Control<ResetPasswordFields | EmailRegFormValues | PhoneRegFormValues>;
  t: (str: string, ...args: any) => string;
  onChange?: () => void;
  onInputStart?: () => void;
  autoComplete?: string;
  id?: string;
  placeholder?: string;
  label?: string;
};

export enum PasswordField {
  Password = 'password'
}

type ErrorContainerProps = {
  children: React.ReactNode;
  isError: boolean;
};

const ErrorContainer: FC<ErrorContainerProps> = ({ children, isError }) => {
  return (
    <span
      className={classNames('text-sm', {
        'text-error': isError
      })}
    >
      {children}
    </span>
  );
};

type PasswordErrorType = 'length' | 'numbers' | 'letters';

export const Password: FC<Props> = ({
  control,
  t,
  onChange,
  onInputStart,
  autoComplete,
  id,
  label,
  placeholder
}) => {
  const [showPasswordHint, setShowPasswordHint] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<PasswordErrorType[]>([]);
  const errorLengthRef = React.useRef(passwordErrors.length);

  const validatePassword = (value?: string): string | boolean => {
    const stringValue = typeof value !== 'string' ? '' : value;

    const errors: PasswordErrorType[] = [];
    const validators = [
      validateMinLength.bind(null, MIN_PASSWORD_LENGTH, stringValue, 'length'),
      validateNumbersInString.bind(null, stringValue, 'numbers'),
      validateCharactersInString.bind(null, stringValue, 'letters')
    ];

    validators.forEach((validator) => {
      const validationError = validator();
      if (validationError) errors.push(validationError as PasswordErrorType);
    });
    setPasswordErrors(errors);
    errorLengthRef.current = errors.length;
    if (errors.length === 0) return true;

    return '';
  };

  const getValidationStatus = () => {
    return errorLengthRef.current ? 'emptyMessage' : true;
  };

  return (
    <div>
      <Controller
        name={PasswordField.Password}
        control={control}
        rules={{
          required: t('errors.required'),
          validate: () => getValidationStatus()
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <TextInput
                label={label}
                name={field.name}
                className={'md:min-h-[32px]'}
                type={'password'}
                placeholder={placeholder}
                onChange={(e) => {
                  const filteredValue = e.target.value
                    .split('')
                    .filter((symb) => !FORBIDDEN_SYMBOLS.includes(symb))
                    .join('');

                  validatePassword(filteredValue);

                  const { target } = e;
                  const updatedTarget = { ...target, value: filteredValue };
                  field.onChange({ ...e, target: updatedTarget });

                  onChange?.();
                }}
                value={field.value}
                errorMessage={error?.message}
                hideErrorMessage
                onFocus={() => {
                  setShowPasswordHint(true);
                  validatePassword(field.value);
                }}
                onBlur={field.onBlur}
                onInputStart={onInputStart}
                autoComplete={autoComplete}
                id={id}
                bottomContent={
                  <div className="mt-[4px] md:mt-[8px]">
                    {error?.type === 'required' && error.message && (
                      <div className="text-sm text-error">{error?.message}</div>
                    )}
                    {showPasswordHint && passwordErrors.length ? (
                      <div className="text-csm text-grey-light">
                        <ErrorContainer isError={passwordErrors.includes('length')}>
                          {t('errors.password.length')}
                        </ErrorContainer>{' '}
                        <br />{' '}
                        <ErrorContainer isError={passwordErrors.includes('numbers')}>
                          {t('errors.password.numbers')}
                        </ErrorContainer>{' '}
                        <ErrorContainer isError={passwordErrors.includes('letters')}>
                          {t('errors.password.and')}
                        </ErrorContainer>{' '}
                        <ErrorContainer isError={passwordErrors.includes('letters')}>
                          {t('errors.password.letters')}
                        </ErrorContainer>{' '}
                        <ErrorContainer
                          isError={
                            passwordErrors.includes('letters') || passwordErrors.includes('numbers')
                          }
                        >
                          {t('errors.password.requiredMatch')}
                        </ErrorContainer>
                      </div>
                    ) : null}
                  </div>
                }
              />
            </>
          );
        }}
      />
    </div>
  );
};
