import { FC, memo } from 'react';
import Link from 'next/link';
import { RecoverySucceedIcon } from '@/shared/ui/icons/recovery-succeed';
import { useScopedI18n } from '@/shared/locales/client';
import { RoutesConfig } from '@/shared/routes/config';

const RecoverySuccessScreen: FC = () => {
  const t = useScopedI18n('recovery');

  return (
    <div className="w-full rounded-md shadow md:w-58t">
      <h1 className="text-gray-900 mb-2t text-lg font-medium tracking-tight text-primary md:text-3xl">
        {t('title')}
      </h1>
      <div className="rounded-md bg-main-light px-2 py-4 md:p-5">
        <p className="-mb-1 text-xl"> {t('success.message')}</p>
        <RecoverySucceedIcon />
        <Link
          href={RoutesConfig.login}
          className="mb-18px mt-34px block items-center justify-center rounded-md bg-primary px-2t py-14px text-center text-xl font-semibold leading-normal text-black transition-colors duration-200 ease-out hover:bg-primary-dark"
        >
          {t('success.cta')}
        </Link>
      </div>
    </div>
  );
};

export default memo(RecoverySuccessScreen);
