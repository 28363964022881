'use client';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { EmailRegFormValues } from '@/features/registration/ui/email-registration-form';
import { PhoneRegFormValues } from '@/features/registration/ui/phone-registration-form';
import { Checkbox } from '@/shared/ui';
import { sendRegistrationAgreementClickEvent } from '@/shared/lib/gtm/events/registration-events';
import { useAppConfig } from '@/entities/app-config';
import parse, { DOMNode } from 'html-react-parser';

type Props = {
  control: Control<EmailRegFormValues | PhoneRegFormValues>;
};

export enum AgreeSectionFieldNames {
  RuleAgreement = 'RuleAgreement',
  PersonalData = 'PersonalData'
}

export const AgreeSection: FC<Props> = ({ control }) => {
  const {
    config: { registrationAgreementText }
  } = useAppConfig();

  return (
    <div className={'md:leading-17 mt-2 text-12 leading-16 md:mt-4 md:text-14'}>
      {registrationAgreementText && registrationAgreementText !== '<p></p>' && (
        <Controller
          name={AgreeSectionFieldNames.RuleAgreement}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Checkbox
              className="!items-start [&_a]:text-primary [&_a]:underline"
              checked={field.value}
              name={field.name}
              onChange={(event) => {
                sendRegistrationAgreementClickEvent(event.target.checked);
                field.onChange(event);
              }}
            >
              {parse(registrationAgreementText)}
            </Checkbox>
          )}
        />
      )}
    </div>
  );
};

export default AgreeSection;
